import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";


const BizDevReps = ({pageContext}) => {
    return <Layout
        pageContext={pageContext}
    >
        <Seo title={"Business Development"} />
        <div className="content-row bizdev">
            <div className="seventy">
                <h1>Make Your Vancouver Conference Unforgettable</h1>
                <p className="sub-title">
                    Looking to add something special to your conference or meeting in Vancouver? VanWalks is here to help! Our expertly crafted walking tours provide a unique and exciting way for your attendees to experience the city, with historical context, modern events, and local interpretation all combined for a fun and educational experience.
                </p>
                <h2>Self-Paced Tours</h2>
                <p className="sub-title">
                    VanWalks is an easy and hassle-free addition to your conference schedule. Our web app is designed to be incredibly user-friendly, with no app to download, no accounts to create, and no passwords to remember.
                </p>
                <p className="sub-title">
                    This makes it incredibly easy to integrate VanWalks into your event, allowing your attendees to explore Vancouver at their own pace without any hassle or delay.
                </p>
                <h2>Volume Discounts Available</h2>
                <p className="sub-title">
                    At VanWalks, we understand that conferences and meetings can be large affairs with many attendees. That&apos;s why our walking tours can accommodate a much larger group compared to traditional walking tours or group activites. Plus, VanWalks is a lot cheaper and more fun than renting a bus to tour the city, making it an affordable and practical option for your event.
                </p>
                <p className="sub-title">
                    We offer volume discounts on our premium content, making it easy to give your attendees an unforgettable Vancouver experience.
                </p>
                <h1>Contact Us Now</h1>
                <p className="sub-title">
                    Contact us now to learn more about our volume discounts and start planning your unforgettable Vancouver experience. Email us at <a href="mailto:info@vanwalks.ca">info@vanwalks.ca</a> to get started today!
                </p><br/><br/>
                <h2>Custom Interactive Activities</h2>
                <p className="sub-title">
                    Looking for a unique way to engage your conference attendees or team members? VanWalks is currently developing custom interactive activities that use our web app as a base, including scavenger hunts and other team-building experiences.<br/><br/>
                    These interactive activities will allow your attendees to explore Vancouver in a fun and engaging way, all while using the user-friendly VanWalks platform. We&apos;ll work with you to create a custom activity tailored to your specific needs and interests, whether it&apos;s a team-building exercise or a fun way to explore the city.<br/><br/>
                    Stay tuned for updates on these exciting new developments, and contact us to learn more about how we can help take your event to the next level.
                </p>
            </div>
            <div className="thirty screenshot-bizdev">
                <StaticImage
                    className="screenshot screenshot-1"
                    src="../../images/home_screenshot_1.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="VanWalks Screenshot"
                    objectFit="contain"
                    placeholder={"none"}
                />
                <StaticImage
                    className="screenshot screenshot-2"
                    src="../../images/home_screenshot_2.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="VanWalks Screenshot"
                    objectFit="contain"
                    placeholder={"none"}
                />
            </div>
        </div>
    </Layout>
}

export default BizDevReps